import React from "react"
import { ReactComponent as Bag } from "../../../static/icons/white/bag.svg"

import styles from "./alert.module.css"

function Alert({ message }) {
  return (
    <div role="alert" className={styles.alert}>
      <p className={styles.message}>{message} </p>
      <Bag width="32" height="32" className={styles.icon} />
    </div>
  )
}

export default Alert
