import React from "react"
import { Link } from "gatsby"
import { useCartCount } from "gatsby-theme-shopify-manager"

import { ReactComponent as IconFacebook } from "../../../static/icons/black/facebook.svg"
import { ReactComponent as IconInstagram } from "../../../static/icons/black/instagram.svg"
import { ReactComponent as IconWhatsapp } from "../../../static/icons/black/whatsapp.svg"
import { ReactComponent as IconCall } from "../../../static/icons/black/call.svg"
import { ReactComponent as IconBag } from "../../../static/icons/black/bag.svg"

import classNames from "classnames"

import styles from "./social.module.css"

const Social = ({ hasCart, isInverted, className, isHeader }) => {
  const socialClass = classNames(styles.social, { [styles.inverted]: isInverted, [styles.header]: isHeader }, className)
  const count = useCartCount()

  return (
    <div className={socialClass}>
      <span className={styles.wrapper}>
        <span className={styles.iconWrapper}>
          <IconCall className={`${styles.icon} ${styles.phone}`} />
        </span>
        <span className={styles.number}>5555969400</span>
      </span>
      <a
        className={styles.wrapper}
        href="https://web.whatsapp.com/send?phone=525551067077&text=Hola,%20me%20interesa%20me%20llamen"
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles.iconWrapper}>
          <IconWhatsapp className={`${styles.icon} ${styles.whatsapp}`} />
        </span>
        <span className={styles.number}>5551067077</span>
      </a>
      <div className={styles.socials}>
        <a href="https://www.facebook.com/latelierdf/" target="_blank" rel="noreferrer">
          <IconFacebook className={`${styles.icon} ${styles.facebook}`} />
        </a>
        <a href="https://www.instagram.com/latelierdesfleurs/?igshid=pkxgqa34mzmm" target="_blank" rel="noreferrer">
          <IconInstagram className={`${styles.icon} ${styles.ig}`} />
        </a>
      </div>
      {hasCart && (
        <>
          <Link to="/cart">
            <IconBag className={`${styles.icon} ${styles.bg}`} />
          </Link>
          <span className={styles.cart}>({count})</span>
        </>
      )}
    </div>
  )
}

export default Social
