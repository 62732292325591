import React from "react"
import classNames from "classnames"

import styles from "./card.module.css"

function Card({ title, paragraphs, isStretch }) {
  const titleClass = classNames(styles.title, { [styles.stretch]: isStretch })
  return (
    <article className={styles.card}>
      <h3 className={titleClass}>{title}</h3>
      {paragraphs.map((p, idx) => (
        <p key={`p-${idx}`} className={styles.text}>
          {p}
        </p>
      ))}
    </article>
  )
}

export default Card
