import React from "react"
import { Link } from "gatsby"

import styles from "./subnav.module.css"

export default function SubNav({ currentLink, links }) {
  return (
    <div className={styles.subnav}>
      <div className={styles.current}>
        <span>{currentLink}</span>
      </div>
      <div className={styles.links}>
        {links &&
          links.map((l, idx) => (
            <Link key={`sublink-${idx}`} className={styles.link} activeClassName={styles.active} to={l.url}>
              {l.name}
            </Link>
          ))}
      </div>
    </div>
  )
}
