import React, { useState } from "react"
import { Link } from "gatsby"
import { Layout, Image, Alert } from "../"
import { formatPrice } from "../../utils/currency"
import { useAddItemToCart } from "gatsby-theme-shopify-manager"

import styles from "./product-details.module.css"

function renderMainImage(img) {
  return (
    img && (
      <figure className={styles.container}>
        <Image name={img.localFile.childImageSharp.fluid.originalName} className={styles.image} />
      </figure>
    )
  )
}

function renderGallery(images, onThumbnailClick) {
  return (
    images &&
    images.length > 0 && (
      <ul className={styles.gallery}>
        {images.map(image => (
          <li className={styles.galleryItem} key={`product-thumbnail-${image.id}`}>
            <button onClick={() => onThumbnailClick(image)} className={styles.imageButton} arial-label="Cambiar imagen de la galería">
              <Image name={image.localFile.childImageSharp.fluid.originalName} className={styles.thumbnail} />
            </button>
          </li>
        ))}
      </ul>
    )
  )
}

export default function ProductDetails({ title, description, images, variants, location }) {
  const [quantity, setQuantity] = useState(1)
  const [currentThumbnail, setCurrentThumbnail] = useState(images[0])
  const [displayAlert, setDisplayAlert] = useState(false)
  const addItemToCart = useAddItemToCart()

  const handleOnAddToCart = async () => {
    try {
      await addItemToCart(variants[0].shopifyId, quantity)
      setDisplayAlert(true)
      window.scroll(0, 0)
      setTimeout(() => setDisplayAlert(false), 1500)
    } catch {
      alert("Algo no ha salido como esperábamos, inténtalo nuevamente más tarde.")
    }
  }

  const subNavLinks = [
    { name: "DE LÍNEA", url: "/arreglos/de-linea" },
    { name: "ECOLÓGICOS", url: "/arreglos/ecologicos" },
    { name: "EDICIÓN LIMITADA", url: "/arreglos/edicion-limitada" },
    { name: "ESPECIALES", url: "/arreglos/especiales" },
  ]

  const redirectLink = location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)

  return (
    <Layout currentLink="Arreglos" showSubMenu={true} links={subNavLinks}>
      {displayAlert && <Alert message="El producto se agregó con éxito al carrito." />}
      <section className="product-details">
        <div className={styles.preview}>
          {renderMainImage(currentThumbnail)}
          {renderGallery(images, setCurrentThumbnail)}
        </div>

        <div className={styles.details}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description || "Descripción no disponible."}</p>

          <div className={styles.wrapper}>
            <div className={styles.priceContainer}>
              <h3 className={styles.title}>Precio + IVA</h3>
              <div className={styles.price}>
                <span className={styles.symbol}>$</span>
                <span className={styles.priceAmount}>{formatPrice(variants[0].price)}</span>
              </div>
            </div>
            <div className={styles.quantity}>
              <h3 className={styles.title}>Cantidad</h3>
              <div className={styles.actions}>
                <button
                  className={styles.actionButton}
                  onClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
                  aria-label="Quitar artículo"
                >
                  -
                </button>
                <div className={styles.amount}>
                  <span>{quantity}</span>
                </div>
                <button className={styles.actionButton} onClick={() => setQuantity(prevQuantity => prevQuantity + 1)} arial-label="Agregar artículo">
                  +
                </button>
              </div>
            </div>
          </div>

          <button className={styles.button} onClick={handleOnAddToCart} arial-label="Enviar artículo a mi carrito">
            Añadir a Carrito
          </button>

          <Link className={styles.button} to="/cart">
            Ver Carrito
          </Link>

          <Link className={styles.button} to={redirectLink}>
            Regresar
          </Link>
        </div>
      </section>
    </Layout>
  )
}
