import React from "react"
import { ReactComponent as BagIcon } from "../../../static/icons/black/bag.svg"
import { Link } from "gatsby"
import { Image } from "../"
import { formatPrice } from "../../utils/currency"

import styles from "./product.module.css"

const Product = ({ image, title, price, url }) => {
  return (
    <Link to={url} className={styles.product}>
      <article className={styles.wrapper}>
        <figure className={styles.thumbnail}>
          <Image
            name={image.localFile.childImageSharp.fluid.originalName}
            className={styles.image}
          />
        </figure>
        <div className={styles.details}>
          <div className={styles.information}>
            <h3 className={styles.title}>{title}</h3>
            <span className={styles.price}>
              <span className={styles.symbol}>$</span> {formatPrice(price)}
            </span>
          </div>
          <button className={styles.button} aria-label="Abrir carrito de compra">
            <BagIcon width="26" height="26" />
          </button>
        </div>
      </article>
    </Link>
  )
}

export default Product
