import React from "react"
import { Link } from "gatsby"
import { ReactComponent as Logo } from "../../../static/logos/footer-logo.svg"
import { navLinks } from "../../utils/links"

import Social from "../social/social"
import styles from "./footer.module.css"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <figure className={styles.container}>
        <Logo className={styles.logo} role="img" />
      </figure>
      <div className={styles.details}>
        <ul className={styles.navigation}>
          {navLinks.map((link, index) => (
            <li className={styles.item} key={`footer-link-${index}`}>
              <Link className={styles.link} to={link.url}>
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.email}>
          <h4 className={styles.title}>Escríbenos un mail</h4>
          <span className={styles.hello}>hola@latelierdesfleurs.com.mx</span>
        </div>
        <div className={styles.copyright}>
          <span className={styles.text}>Derechos Reservados &copy; 2020</span>
          <span className={styles.text}>
            Web Design by <strong><a href="http://www.noxmedia.mx" target="_blank" rel="noreferrer" className={styles.noxmedia}>NoxMedia</a></strong>
          </span>
        </div>
        <div className={styles.social}>
          <Social />
        </div>
      </div>
    </footer>
  )
}
