import React from "react"
import { useCart, useCheckoutUrl } from "gatsby-theme-shopify-manager"
import { useForm, Controller } from "react-hook-form"
import { navigate } from "gatsby"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import { startOfTomorrow } from "date-fns"
import axios from "axios"
import es from "date-fns/locale/es"

import styles from "./checkout.module.css"
import "react-datepicker/dist/react-datepicker.css"

const Checkout = () => {
  const { register, handleSubmit, errors, reset, control } = useForm()
  const cart = useCart()
  const FORM_NAME = "latelier-order-form"
  let deliverDate = undefined

  registerLocale("es", es)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const cartItemsToEmail = items => {
    return items.reduce((acc, el) => {
      const title = el.title.toLowerCase().replaceAll(" ", "_")
      acc[title] = `${el.title}: ${el.quantity}`
      return acc
    }, {})
  }
  const products = cart && cart.lineItems ? cartItemsToEmail(cart.lineItems) : undefined
  const productsAsAString = products
    ? Object.keys(products)
        .map(el => products[el])
        .join(", ")
    : undefined

  const checkoutUrl = useCheckoutUrl()

  const createAndClickCheckoutLink = url => {
    const link = document.createElement("a")
    link.href = url
    link.target = "_blank"
    link.rel = "noreferrer"
    link.click()
  }

  const onSubmit = data => {
    deliverDate = formatDate(data.fecha_de_entrega_del_producto)
    const formData = {
      ...data,
      fecha_de_entrega_del_producto: formatDate(data.fecha_de_entrega_del_producto),
      fecha_final_de_entrega: deliverDate,
    }

    axios("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: encode({
        "form-name": FORM_NAME,
        ...formData,
      }),
    })
      .then(() => {
        createAndClickCheckoutLink(checkoutUrl)
        reset()
        navigate("/arreglos/de-linea")
      })
      .catch(error => console.error(error))
  }

  const formatDate = date => {
    const formatter = new Intl.DateTimeFormat("es", { month: "long" })
    const month = formatter.format(date)
    const day = date.getUTCDate()
    const year = date.getUTCFullYear()
    return `${day} de ${month} del ${year}`
  }

  const isWeekday = date => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      name={FORM_NAME}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={FORM_NAME} />

      <h1 className={styles.title}>Detalles de Entrega</h1>
      <p className={styles.disclaimer}>
        Debido a la exclusividad de nuestros productos, la disponibilidad para entrega inmediata se encuentra sujeta a
        previa confirmación del L´ATELIER DES FLEURS. Nuestras zonas para entrega son: Interlomas, Tecamachalco, Bosque
        real, Bosques de las lomas, Virreyes, Santa Fe, Palmas, Reforma, Insurgentes, Roma norte, Roma sur, Condesa y
        Pedregal (Consulta otras zonas donde podríamos entregar en nuestros números telefónicos).
      </p>

      <div className={styles.formContainer}>
        <div className={styles.column}>
          <h3 className={styles.subtitle}>Datos de quien envía</h3>
          <div className={styles.field}>
            <label htmlFor="nombreQuienEnvia" className={styles.label}>
              Nombre de quien envía:
            </label>
            <input
              type="text"
              name="nombre_de_quien_envia"
              className={styles.input}
              id="nombreQuienEnvia"
              maxLength="80"
              ref={register({
                required: true,
                maxLength: { value: 80, message: "El campo es demasiado extenso" },
              })}
            />
            {errors.nombreQuienEnvia && (
              <span role="alert" className={styles.error}>
                Este campo es requerido
              </span>
            )}
          </div>
          <div className={styles.field}>
            <label htmlFor="telefonoQuienEnvia" className={styles.label}>
              Teléfono de quien envía:
            </label>
            <input
              type="tel"
              name="telefono_de_quien_envia"
              className={styles.input}
              id="telefonoQuienEnvia"
              maxLength="15"
              ref={register({
                required: "Este campo es requerido",
              })}
            />
            {errors.telefonoQuienEnvia && (
              <span role="alert" className={styles.error}>
                {errors.telefonoQuienEnvia.message}
              </span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="emailQuienEnvia" className={styles.label}>
              Email de quien envía:
            </label>
            <input
              type="email"
              name="email_de_quien_envia"
              className={styles.input}
              id="emailQuienEnvia"
              maxLength="60"
              ref={register({
                required: "Este campo es requerido",
                maxLength: { value: 60, message: "El campo es demasiado extenso." },
                pattern: {
                  value: new RegExp(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
                  ),
                  message: "Ingresa un formato válido de correo electrónico.",
                },
              })}
            />
            {errors.emailQuienEnvia && (
              <span role="alert" className={styles.error}>
                {errors.emailQuienEnvia.message}
              </span>
            )}
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.subtitle}>Datos de quien recibe</h3>
          <div className={styles.field}>
            <label htmlFor="nombreQuienRecibe" className={styles.label}>
              Nombre de quien recibe:
            </label>
            <input
              type="text"
              name="nombre_de_quien_recibe"
              className={styles.input}
              id="nombreQuienRecibe"
              maxLength="80"
              ref={register({
                required: true,
                maxLength: { value: 80, message: "El campo es demasiado extenso." },
              })}
            />
            {errors.nombreQuienRecibe && (
              <span role="alert" className={styles.error}>
                Este campo es requerido
              </span>
            )}
          </div>
          <div className={styles.field}>
            <label htmlFor="telefonoQuienRecibe" className={styles.label}>
              Teléfono de quien recibe:
            </label>
            <input
              type="tel"
              name="telefono_de_quien_recibe"
              className={styles.input}
              id="telefonoQuienRecibe"
              maxLength="15"
              ref={register({
                required: "Este campo es requerido",
              })}
            />
            {errors.telefonoQuienRecibe && (
              <span role="alert" className={styles.error}>
                {errors.telefonoQuienRecibe.message}
              </span>
            )}
          </div>
          <div className={styles.hidden}>
            <label htmlFor="fechaFinaldeEntrega" className={styles.label}>
              Fecha de entrega del producto
            </label>
            <textarea
              type="text"
              name="fecha_final_de_entrega"
              defaultValue={deliverDate}
              className={styles.textarea}
              ref={register}
              id="fechaFinaldeEntrega"
              readOnly
            ></textarea>
          </div>
          <div className={styles.field}>
            <label htmlFor="fechaDeEntrega" className={styles.label}>
              Fecha de Entrega:
            </label>
            <Controller
              control={control}
              name="fecha_de_entrega_del_producto"
              defaultValue={startOfTomorrow()}
              render={({ onChange, onBlur, value }) => (
                <ReactDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  locale="es"
                  className={styles.datepicker}
                  dateFormat="dd/MM/yyyy"
                  filterDate={isWeekday}
                  calendarClassName={styles.calendar}
                  minDate={startOfTomorrow()}
                  onFocus={e => e.target.blur()}
                />
              )}
              rules={{ required: true }}
            />
            {errors.fechaDeEntrega && (
              <span role="alert" className={styles.error}>
                Este campo es requerido
              </span>
            )}
          </div>
          <div className={styles.field}>
            <label htmlFor="horaDeEntrega" className={styles.label}>
              Hora de Entrega:
            </label>
            <select
              name="hora_de_entrega_del_producto"
              id="horaDeEntrega"
              ref={register({ required: true })}
              className={styles.input}
            >
              <option value="" defaultValue="Selecciona una hora">
                Selecciona una hora
              </option>
              <option value="07:00 - 08:00 hrs">07:00 - 08:00 hrs</option>
              <option value="08:00 - 09:00 hrs">08:00 - 08:00 hrs</option>
              <option value="09:00 - 10:00 hrs">09:00 - 10:00 hrs</option>
              <option value="10:00 - 11:00 hrs">10:00 - 11:00 hrs</option>
              <option value="11:00 - 12:00 hrs">11:00 - 12:00 hrs</option>
              <option value="12:00 - 13:00 hrs">12:00 - 13:00 hrs</option>
              <option value="13:00 - 14:00 hrs">13:00 - 14:00 hrs</option>
              <option value="14:00 - 15:00 hrs">14:00 - 15:00 hrs</option>
              <option value="15:00 - 16:00 hrs">15:00 - 16:00 hrs</option>
              <option value="16:00 - 17:00 hrs">16:00 - 17:00 hrs</option>
              <option value="17:00 - 18:00 hrs">17:00 - 18:00 hrs</option>
              <option value="18:00 - 19:00 hrs">18:00 - 19:00 hrs</option>
            </select>
            {errors.horaDeEntrega && (
              <span role="alert" className={styles.error}>
                Este campo es requerido
              </span>
            )}
          </div>
          <div className={styles.field}>
            <label htmlFor="mensaje" className={styles.label}>
              Escribe tu mensaje:
            </label>
            <textarea
              type="text"
              name="mensaje"
              className={styles.textarea}
              id="mensaje"
              ref={register({
                required: "Este campo es requerido",
                maxLength: { value: 800, message: "El mensaje es demasiado extenso." },
              })}
            ></textarea>
            {errors.mensaje && (
              <span role="alert" className={styles.error}>
                {errors.mensaje.message}
              </span>
            )}
          </div>
          <div className={styles.hidden}>
            <label htmlFor="lista_de_productos" className={styles.label}>
              Lista de productos
            </label>
            <textarea
              type="text"
              name="productos"
              defaultValue={productsAsAString}
              className={styles.textarea}
              ref={register}
              id="productos"
              readOnly
            ></textarea>
          </div>
          <button type="submit" className={styles.submit} aria-label="Finalizar mi compra">
            Enviar pedido
          </button>
        </div>
      </div>
    </form>
  )
}

export default Checkout
