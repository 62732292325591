import React from "react"
import { Image } from "../"
import { Link } from "gatsby"
import styles from "./event-card.module.css"

function EventCard({ title, image, link }) {
  return (
    <Link to={`/eventos/${link}`} className={styles.link}>
      <article className={styles.card}>
        <h3 className={styles.title}>{title}</h3>
        {image.localFile ? (
          <Image name={image.localFile.childImageSharp.fluid.originalName} className={styles.image} />
        ) : (
          <img src={image} alt={title} />
        )}
      </article>
    </Link>
  )
}

export default EventCard
