import React from "react"
import { Image } from "../"
import { titleWithoutPipe } from "../../utils/dom"
import Slider from "react-slick"
import styles from "./event.module.css"

function Event({ products }) {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    speed: 500,
    draggable: false,
    cssEase: "linear",
    arrows: true,
    pauseOnHover: false,
  }

  if (!products || products.length === 0) {
    return null
  }

  const gallery = products[0].images
  console.log(gallery)
  return (
    <Slider {...settings}>
      {gallery.map((image, index) => (
        <article key={`slider-${index}`} className={styles.item}>
          <div className={styles.gallery}>
            <img src={image.originalSrc} alt={image.id} />
          </div>
          <div className={styles.details}>
            <h4 className={styles.title}>{titleWithoutPipe(products[0].title)}</h4>
            <p className={styles.description}>{products[0].description}</p>
          </div>
        </article>
      ))}
    </Slider>
  )
}

export default Event
