import React from "react"

import styles from "./services-list.module.css"

function ServicesList({ services }) {
  return (
    <ul className={styles.list}>
      {services.map((service, idx) => (
        <li className={styles.item} key={`item-${idx}`}>
          {service}
        </li>
      ))}
    </ul>
  )
}

export default ServicesList
