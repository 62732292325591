export const navLinks = [
  {
    url: "/nosotros",
    label: "Nosotros",
  },
  {
    url: "/arreglos/de-linea",
    label: "Arreglos",
  },
  {
    url: "/eventos/sociales",
    label: "Eventos",
  },
  {
    url: "/accesorios",
    label: "Accesorios",
  },
  {
    url: "/home-design",
    label: "Home Design",
  },
]

export const SUB_NAV_EVENTS_LINKS = [
  {
    name: "SOCIALES",
    url: "/eventos/sociales",
  },
  {
    name: "CORPORATIVOS",
    url: "/eventos/corporativos",
  },
]

export const SUB_NAV_SOCIAL_EVENTS_LINKS = [
  {
    name: "BODAS",
    url: "/eventos/sociales/bodas",
  },
  {
    name: "COMPROMISO",
    url: "/eventos/sociales/compromiso",
  },
  {
    name: "BAR MITZVÁ",
    url: "/eventos/sociales/barmitzva",
  },
  {
    name: "DESAYUNOS",
    url: "/eventos/sociales/desayunos",
  },
]
