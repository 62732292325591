import React from "react"
import { formatPrice } from "../../utils/currency"
import { Link } from "gatsby"
import { useCartItems, useUpdateItemQuantity, useCart } from "gatsby-theme-shopify-manager"

import styles from "./cart.module.css"

function getCartTotals(cart) {
  if (cart == null) {
    return { tax: 0, total: 0 }
  }
  const tax = Number(cart.totalTax)
  const total = Number(cart.lineItemsSubtotalPrice.amount)

  return {
    tax,
    total,
  }
}

const CartTable = ({ item, removeFromCart }) => (
  <table className={styles.table}>
    <tbody className={styles.body}>
      <tr>
        <th>Precio</th>
        <th>Cantidad</th>
        <th>Total</th>
        <th rowSpan="2" className={styles.deleteCol}>
          <button className={styles.delete} onClick={() => removeFromCart(item.variant.id)} arial-label="Eliminar del carrito">
            Borrar X
          </button>
        </th>
      </tr>
      <tr>
        <td>${formatPrice(item.variant.price)}</td>
        <td>{item.quantity}</td>
        <td>${formatPrice(item.variant.price * item.quantity)}</td>
      </tr>
    </tbody>
  </table>
)

const CartCard = ({ item, removeFromCart }) => (
  <div className={styles.card}>
    <span className={styles.cardQuantity}>
      {item.quantity} {item.quantity > 1 ? "Piezas" : "Pieza"}
    </span>
    <h4 className={styles.cardPrice}>${formatPrice(item.variant.price)}</h4>
    <button className={styles.cardDelete} onClick={() => removeFromCart(item.variant.id)} aria-label="Eliminar del carrito">
      Borrar X
    </button>
  </div>
)

const CartBreakdown = ({ total, tax }) => (
  <table className={styles.breakdownTable}>
    <tbody className={styles.breakdownBody}>
      <tr>
        <td colSpan="2">
          <b>Desglose de Pedido</b>
        </td>
      </tr>
      <tr>
        <td>Productos</td>
        <td>${formatPrice(total)}</td>
      </tr>
      <tr>
        <td>IVA</td>
        <td>${formatPrice(total * .16)}</td>
      </tr>
      <tr>
        <td>
          <b>Sub total</b>
        </td>
        <td>${formatPrice(total * 1.16)}</td>
      </tr>
      <tr>
        <td>
          <b>Envío (Incluye IVA)</b>
        </td>
        <td>${formatPrice(120)}</td>
      </tr>
      <tr>
        <td>
          <b>Total</b>
        </td>
        <td>${formatPrice(total + (total * .16) + 120)}</td>
      </tr>
    </tbody>
  </table>
)

const CartItem = ({ items, removeFromCart }) => {
  if (items.length < 1) {
    return <p>No hay artículos agregados al carrito.</p>
  }

  return items.map((item, index) => (
    <article className={styles.item} key={`${item.id}-${index}`}>
      <figure className={styles.thumbnail}>
        <img src={item.variant.image.src} alt={item.variant.image.altText} />
      </figure>
      <div className={styles.details}>
        <h4 className={styles.itemTitle}>{item.title}</h4>
        <CartCard item={item} removeFromCart={removeFromCart} />
        <CartTable item={item} removeFromCart={removeFromCart} />
      </div>
    </article>
  ))
}

function Cart() {
  const items = useCartItems()
  const updateItemQuantity = useUpdateItemQuantity()
  const updatedCart = useCart()
  const { tax, total } = getCartTotals(updatedCart)

  const removeFromCart = async variantId => {
    try {
      await updateItemQuantity(variantId, 0)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="cart">
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Mis Compras</h3>
        <div className={styles.items}>
          <CartItem items={items} removeFromCart={removeFromCart} />
        </div>
        <div className={styles.breakdown}>
          <CartBreakdown tax={tax} total={total} />

          <Link to="/detalles-compra" className={styles.button}>
            Detalles de Entrega
          </Link>

          <Link to="/arreglos/de-linea" className={styles.button}>
            Continuar comprando
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Cart
