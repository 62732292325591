import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Image({ name, className }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" } }) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 915, quality: 70) {
                    originalName
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.allFile.edges.find(edge => {
          return edge.node.childImageSharp.fluid.originalName === name
        })

        if (!image) {
          return null
        }

        return <Img fluid={image.node.childImageSharp.fluid} className={className} />
      }}
    />
  )
}
