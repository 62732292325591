import React from "react"

import { Header, Footer, SubNav } from "./"

const Layout = ({ children, footerless, currentLink, showSubMenu, isNavSticky, isInverted, links, isIndex }) => (
  <>
    <Header isNavSticky={isNavSticky} isInverted={isInverted} isIndex={isIndex} />
    {showSubMenu && <SubNav currentLink={currentLink} links={links} />}
    <main>{children}</main>
    {!footerless && <Footer />}
  </>
)

export default Layout
