import React from "react"
import { Link } from "gatsby"
import { ReactComponent as Logo } from "../../../static/logos/header-logo.svg"
import { Social, MobileNav } from "../"
import { navLinks } from "../../utils/links"
import classNames from "classnames"

import styles from "./header.module.css"

export default function Header({ isNavSticky, isInverted, isIndex }) {
  const headerClasses = classNames(styles.header, { [styles.sticky]: isNavSticky, [styles.inverted]: isInverted })
  return (
    <header className={headerClasses}>
      <div className={styles.wrapper}>
        <Link to="/" className={styles.logo}>
          <Logo className={styles.img} />
        </Link>
        <nav className={styles.nav}>
          {navLinks.map((link, index) => (
            <Link
              to={link.url}
              className={styles.link}
              activeClassName={styles.active}
              key={`link-${index}`}
              partiallyActive={true}
            >
              {link.label}
            </Link>
          ))}
        </nav>
      </div>
      <Social hasCart={true} isInverted={isInverted} isHeader={true} />
      <MobileNav isIndex={isIndex} />
    </header>
  )
}
