import React, { useState } from "react"
import { Link } from "gatsby"
import { ReactComponent as Logo } from "../../../static/logos/header-logo.svg"
import { ReactComponent as Close } from "../../../static/icons/close.svg"
import { ReactComponent as CircleLogo } from "../../../static/logos/footer-logo.svg"
import { ReactComponent as IconFacebook } from "../../../static/icons/black/facebook.svg"
import { ReactComponent as IconInstagram } from "../../../static/icons/black/instagram.svg"
import { ReactComponent as IconWhatsapp } from "../../../static/icons/black/whatsapp.svg"
import { ReactComponent as IconCall } from "../../../static/icons/black/call.svg"
import { ReactComponent as Ham } from "../../../static/icons/ham.svg"
import { navLinks } from "../../utils/links"
import classNames from "classnames"

import styles from "./mobile-nav.module.css"

const HamMenu = ({ onClick, isHome }) => {
  const hamClassname = classNames(styles.ham, { [styles.hamIndex]: isHome })

  return (
    <button className={styles.hamButton} onClick={onClick} aria-label="Desplegar menú">
      <Ham className={hamClassname} />
    </button>
  )
}

function MobileNav({ isIndex }) {
  const [show, setShow] = useState(false)
  if (!show) {
    return <HamMenu onClick={() => setShow(true)} isHome={isIndex} />
  }

  return (
    show && (
      <div className={styles.mobileNav}>
        <header className={styles.header}>
          <Logo className={styles.logo} />
          <button className={styles.closeButton} onClick={() => setShow(false)} aria-label="Cerrar menú">
            <Close className={styles.close} />
          </button>
        </header>
        <nav className={styles.nav}>
          {navLinks.map((link, idx) => (
            <li key={`item-${idx}`}>
              <Link to={link.url}>{link.label}</Link>
            </li>
          ))}
        </nav>
        <div className={styles.circleLogo}>
          <CircleLogo className={styles.circleLogoImage} />
        </div>
        <div className={styles.email}>
          <h4 className={styles.title}>Escríbenos un mail</h4>
          <span className={styles.hello}>hola@latelierdesfleurs.com.mx</span>
        </div>
        <div className={styles.details}>
          <span className={styles.iconWrapper}>
            <IconCall className={`${styles.icon} ${styles.call}`} />
          </span>
          <span className={styles.number}>55969400</span>
        </div>
        <div className={styles.details}>
          <span className={styles.iconWrapper}>
            <IconWhatsapp className={`${styles.icon} ${styles.whatsapp}`} />
          </span>
          <span className={styles.number}>55969400</span>
        </div>
        <div className={styles.social}>
          <a className={styles.fb} href="https://www.facebook.com/latelierdf/" target="_blank" rel="noreferrer">
            <IconInstagram className={`${styles.icon}`} />
          </a>
          <a
            className={styles.ig}
            href="https://www.instagram.com/latelierdesfleurs/?igshid=pkxgqa34mzmm"
            target="_blank"
            rel="noreferrer"
          >
            <IconFacebook className={`${styles.icon}`} />
          </a>
        </div>
      </div>
    )
  )
}

export default MobileNav
